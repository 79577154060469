import React from 'react'
import login from '../../images/login.png';
import register from '../../images/register.png';
import sample from '../../images/sample.png';
import side from '../../images/side.png';

const help = () => {
    return (
        <div align="right">
            <h2>
                راهنمای استفاده از اپلیکشن یادیاران
            </h2>
            <h4>
                
                درج اطلاعات در سایت منحصرا برای آزادگان عزیز می باشد لذا تقاضامندیم به عنوان دیگر وارد سایت نشوید
    
                <p>
                   بعد ورود به سایت با دامنه
                   </p>

                 www.yadyaran.com 
                 <p>
                 بایستی ثبت نام در سایت انجام گردد. با زدن دکمه ثبت نام فرم زیر نمایش داده میشود
                 </p>
                 <img src={login} alt="icon" height="350px" />
                 <p>
                    در صورتیکه قبلا ثبت نام کردید در اینجا با زدن ایمیل و رمز عبور وارد سایت میشود در غیر اینصورت 
                    بایستی گزینه پایین فرم (من نیاز به ثبت نام دارم )را بزنید تا فرم زیر نمایش داده میشود

                    </p>  
                    <p>
                    <img src={register} alt="icon" height="350px" />
                    </p>
                    <p>
                        برای تکمیل فرم سعی نمایید که اطلاعات شما درست و با دقت وارد گردد در این بخش حتما کامپیوترتان را در حالت
                         <h2>  زبان انگلیسی  </h2>
                        قرار دهید تا دچار مشکل نشوید. از رمز عبوری استفاده نماید که فراموشتان نشود چون رمز عبور قابل برگشت نمی باشد.
                    </p>
                    <p>
                     بعد از ورود در قسمت راست صفحه در بخش بالا شما میتوانید دو نوع جستجو انجام دهید اولی با عبارتی که در بخش عناوین عکس ها ذکر شده را برای شما ظاهر میکند و در بخش دوم اسامی و محلی که در عکس ها با علامت هشتگ خورده اند را براحتی پیدا نمایید
                     بنابراین اگه دنبال دوستی از زمان اسارت هست و در بین چند هزار عکس فقط عکس اون شخص برای شما ظاهر میشود  
                    </p>
                    <p>
                    <img src={side} alt="icon" height="350px" />
                    </p>
                   
                    <p>
                        در بخش ورود اطلاعات در ابتدا یه عنوان برای عکس یا خاطرتان قرار دهیدو سپس برای نفرات حاضر در عکس از اسم و مشخصات دوستان اقدام نمایدو یا هر توضیحی که در باره عکس هست را بنویسید  
                        سپس در قسمت تگ برای نفرات در عکس اسامی شان را با نوشتن هر اسم و زدن کلید اینتر هشتگ نماید
                    </p>
                    <p>
                        نمونه ای از فرم ورود عکس یا خاطره
                        </p>
                    <img src={sample} alt="icon" height="350px" />
                    <p>
                    در پایان عکس انتخاب شده را ارسال نمایدو کلید ارسال رو فشاردهید
                    </p>
                    <p>
                        چنانچه سوالی دارید یا نیاز به کمک بیشتری دارید دوستان آزاده شما در گروههای واتس آپ به شما یاری می رسانند. و همچنین می توانید با برادران کارگر ضیغمی و سید موسوی در گروههای واتس آپ تماس بگیرید.
                    </p>

            </h4>
        </div>
    )
}

export default help
