import React from 'react'

const about = () => {
    return (
        <div align="right">
            <h2>
                با سلام و احترام خدمت بینندگان و کاربران آزاده عزیز
            </h2>
            <h4>
                با توجه به گذشت بیش از سه دهه از پایان جنگ عراق علیه ایران امروز شاهد آنیم که رزمندگان آن دوران بمرور زمان از دوستان قدیمی خود دور مانده و به فراموشی سپرده شدند
                لذا تصمیم گرفتم با ایجاد یه سایتی که این توانایی رو برای عزیزانمان بوجود بیاورد تا عکس های دوران ارسات خود را به اشتراک گذاشته و بدینطریق
                یه پل ارتباطی بین شان ایجاد گردد و از هم باخبر گردند
                <p>
                   
                    استفاده از این سایت برای همگان رایگان می باشد لیکن درج اطلاعات فقط از طریق آزادگان عزیز مقدور می باشد
                    چون بدون کد زمان اسارت امکان ورود به سایت جهت درج عکس مقدور نمی باشد
                    </p>  
                    <p>
                        آزادگان عزیز در هنگام ورود اطلاعات از گذاشتن عکس های غیر از دوران اسارت پرهیز نمایند و در صورتیکه اسامی دوستانشان را به یاد دارند می توان با تگ کردند اسم تکاتک آزادگان درون عکس را بنویسند
                        این کار باعث جستجو سریع برای یافتن شخص آزاده موردنظر در موتور هوشمند جستجوگر این سایت می گردد
                        
                    </p>
                    <p>
                        چنانچه شخص آزاده تمایل داشته باشد تا بخشی از اطلاعات شخصی خود(شامل شماره تماس - محل سکونت - شغل یا هر مورد یگر) را درون سایت جهت بازدید و شناسایی دیگر عزیزان آزاده به همدیگر ء میتوانند
                        در سایت قرار دهند تا بدینوسیله از کمک همدیگر بهرمند گردند
                    </p>
                    <p>
                        ضمنا دربخش کامنت میتوانید نظراتان را برای همدیگر اعلام نماین. نوجه کنید نمایش این اطلاعات برای همگان مقدور است لینک درج آن و یا پاک کردن اطلاعات تنها از طریق شخص آزاده ای که وروداطلاعات کرده مقدور می باشد
                    </p>
                    <p>
                        و من الله التوفیق
                    </p>
            </h4>
        </div>
    )
}

export default about
